window.addEventListener("load", function() {
  const setCookie = function (name, value) {
    value = encodeURIComponent(value);

    const cookie_date = new Date();
    //cookie_date.setTime(cookie_date.getTime() + (1000 * (cookie_expiration * 24 * 60 * 60)));
    cookie_date.setFullYear(cookie_date.getFullYear() + 10);
    const expires = '; expires=' + cookie_date.toUTCString();

    let cookie_string = name + '=' + (value || '') + expires + '; Path=/; SameSite=Lax;';
    if (location.protocol === 'https:') {
      cookie_string += ' Secure;';
    }
    document.cookie = cookie_string;
  };

  const getCookie = function (name) {
    let found = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    found = found ? found.pop() : false;
    if (found) {
      try {
        found = JSON.parse(found);
      } catch (e) {
        try {
          found = JSON.parse(decodeURIComponent(found));
        } catch (e) {
          found = false;
        }
      }
    }
    return found;
  };

  document.querySelectorAll('.cookiebanner-reset').forEach(function (reset_btn) {
    reset_btn.onclick = function (e) {
      e.preventDefault();
      document.cookie = 'cookie_consent=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.reload();
    }
  });

  let lang = 'de';
  if (document.getElementsByTagName('html')[0].getAttribute('lang')) {
    lang = document.getElementsByTagName('html')[0].getAttribute('lang');
  }
  let has_consent = getCookie('cookie_consent');
  if (has_consent !== false) {
    ItsOk(lang);
    return;
  }

  const cookiebanner = document.getElementById('cookiebanner');

  const ok = cookiebanner.getElementsByClassName('accept-all')[0];
  ok.onclick = function(e) {
    e.preventDefault();
    setCookie('cookie_consent', JSON.stringify({'analytics': true}));
    document.getElementById('cookiebanner').remove();
    ItsOk(lang);
  };

  const more = cookiebanner.getElementsByClassName('accept-all')[0];

  const abort = cookiebanner.getElementsByClassName('reject')[0];
  abort.onclick = function(e) {
    e.preventDefault();
    setCookie('cookie_consent', JSON.stringify({'analytics': false}));
    document.getElementById('cookiebanner').remove();
  };

  function ItsOk() {
    const value = getCookie('cookie_consent');
    if (value && value.analytics === true) {
      let script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-6W7K5KG45R';
      document.head.appendChild(script);
      script = document.createElement('script');
      script.text = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-6W7K5KG45R\');';
      document.body.appendChild(script);
    }
  }

  var individual = document.getElementById('cookie-settings');
  individual.onclick = function(e) {
    e.preventDefault();
    const csa = document.getElementById('cookie-setting-analytics');
    setCookie('cookie_consent', JSON.stringify({'analytics': csa && csa.checked}));
    document.getElementById('cookiebanner').remove();
    ItsOk(lang);
  };

  cookiebanner.removeClass('d-none')
});
