$('.power-off').click(function(e) {
  e.preventDefault();
  $('body').empty();
  $('body').css('padding-top','0px');
  $('body').css('position','static');
  var now = new Date();
  var month = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
  $('body').css({
    'font-family': 'courier new',
    'color': '#FFF',
    'background': '#0A0A0A'
  });
  $('body').append($('<div>').attr('id','top').css({
    width: '100%',
    height: '0%',
    opacity: 0.9,
    background: 'black',
    position: 'absolute',
    top: 0
  }));
  $('body').append($('<div>').attr('id','center').css({
    width: '100%',
    height: '1px',
    background: 'white',
    position: 'absolute',
    top: '50%',
    display: 'none',
    'z-index': 2
  }));
  $('body').append($('<div>').attr('id','bottom').css({
    width: '100%',
    height: '0%',
    opacity: 0.9,
    background: 'black',
    position: 'absolute',
    bottom: 0
  }));
  window.setTimeout(function() {
    $('body').append($('<div>').addClass('line').text(month[now.getMonth()]+' '+(now.getDate()<10?'0'+now.getDate():now.getDate())+' '+(now.getHours()<10?'0'+now.getHours():now.getHours())+':'+(now.getMinutes()<10?'0'+now.getMinutes():now.getMinutes())+':'+(now.getSeconds()<10?'0'+now.getSeconds():now.getSeconds())+' nextcontrol shutdown[3556]: shutting down for system halt'));
  },700);
  window.setTimeout(function() {
    $('body').append($('<div>').addClass('line').text(month[now.getMonth()]+' '+(now.getDate()<10?'0'+now.getDate():now.getDate())+' '+(now.getHours()<10?'0'+now.getHours():now.getHours())+':'+(now.getMinutes()<10?'0'+now.getMinutes():now.getMinutes())+':'+(now.getSeconds()<10?'0'+now.getSeconds():now.getSeconds())+' nextcontrol init: Switching to runlevel: 6'));
  },1200);
  window.setTimeout(function() {
    $('body').append($('<div>').addClass('line').text(month[now.getMonth()]+' '+(now.getDate()<10?'0'+now.getDate():now.getDate())+' '+(now.getHours()<10?'0'+now.getHours():now.getHours())+':'+(now.getMinutes()<10?'0'+now.getMinutes():now.getMinutes())+':'+(now.getSeconds()<10?'0'+now.getSeconds():now.getSeconds())+' nextcontrol acpid: exiting'));
  },1700);
  window.setTimeout(function() {
    $('body').append($('<div>').addClass('line').text(month[now.getMonth()]+' '+(now.getDate()<10?'0'+now.getDate():now.getDate())+' '+(now.getHours()<10?'0'+now.getHours():now.getHours())+':'+(now.getMinutes()<10?'0'+now.getMinutes():now.getMinutes())+':'+(now.getSeconds()<10?'0'+now.getSeconds():now.getSeconds())+' nextcontrol daemon: daemon: client (pid 2502) killed by signal 15, stopping'));
  },2200);
  window.setTimeout(function() {
    $('body div.line').remove();
      $('div#top').animate({
        height: "50%"
        ,opacity: 1
      }, 400);
      $('div#bottom').animate({
        height: "50%"
        ,opacity: 1
      }, 400, function(){
      $('div#center').css({display: "block"}).animate({
        width: "0%",
        left: "50%"
        }, 300);
      }
    );
    //window.setTimeout(function() {
      //window.close();
      //location.reload();
    //},5000);
  },3500);
});
