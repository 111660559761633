(() => {
  const element = document.getElementById('mapid');
  if (element === null) {
    return;
  }
  //const mymap = L.map(element).setView([53.8647007, 8.7071562], 13);
  const mymap = L.map(element).setView([53.86614, 8.70885], 13);

  L.tileLayer('https://map.nextcontrol.de/tile/{z}/{x}/{y}.png', {
    maxZoom: 18,
    attribution: '',
  }).addTo(mymap);

  L.Icon.Default.prototype.options.iconUrl = '../../images/marker-icon.png';
  L.Icon.Default.prototype.options.iconRetinaUrl = '../../images/marker-icon-2x.png';
  L.Icon.Default.prototype.options.shadowUrl = '../../images/marker-shadow.png';

  L.marker([53.86614, 8.70885]).addTo(mymap)
    .bindPopup("<b>NEXTcontrol GmbH</b><br />Präsident-Herwig-Straße 24<br />27472 Cuxhaven").openPopup();

  const popup = L.popup();
})();
